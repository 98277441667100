import React from 'react';
import '../styles/PoliticaDePrivacidad.css'; // Ensure the CSS file is in the same folder
import logo from '../images/himnario-logo.png'; // Change this to the correct path of your logo

const PoliticaDePrivacidad = () => {
    return (
        <div className="politica-privacidad-container">
            {/* Logo at the top */}
            <img src={logo} alt="Himnario LLDM Logo" className="logo" />
            <ul>
                <h1>Privacy Policy</h1>
            </ul>
            <div className="separator"></div>
            <ul>
                <p><strong>Last Updated:</strong> October 18, 2024</p>
            </ul>

            <p>We appreciate your interest and trust in the "Himnario LLDM" application. The privacy and security of your data are of utmost importance to us. This Privacy Policy aims to provide you with clear information about the handling of data when you use our application.</p>

            <div className="separator"></div>

            <h2>1. Information Collection</h2>
            <h3>1.1. Personal Information</h3>
            <p>
                The "Himnario LLDM" application does not directly collect or store any personally identifiable information from users. However, our application uses Google services that may collect certain information:
            </p>
            <ul>
                <li><strong>Google Play Billing:</strong> Manages the information necessary to process in-app purchases.</li>
                <li><strong>Firebase Cloud Messaging:</strong> Manages device tokens for push notifications.</li>
                <li><strong>Google AdMob:</strong> Manages advertising identifiers and ad preferences.</li>
                <li><strong>Firebase Analytics:</strong> Collects anonymous usage data.</li>
            </ul>
            <p>
                All personal information is handled directly by these Google services under their own privacy policies and security standards.
            </p>

            <h3>1.2. Usage Information</h3>
            <p>
                The Google services integrated into our application may collect:
            </p>
            <ul>
                <li>Anonymous usage statistics of features.</li>
                <li>Application performance data.</li>
                <li>Anonymous browsing patterns.</li>
                <li>Error information.</li>
                <li>Session metrics.</li>
            </ul>
            <p>
                This information is completely anonymous and is processed by Firebase Analytics and Google Analytics.
            </p>

            <div className="separator"></div>

            <h2>2. Use of Information</h2>
            <h3>2.1. Use by Google Services</h3>
            <p>
                The Google services use the information collected to:
            </p>
            <ul>
                <li><strong>Firebase:</strong> Provide anonymous usage and behavior analytics.</li>
                <li><strong>AdMob:</strong> Manage relevant advertising.</li>
                <li><strong>Play Billing:</strong> Process purchase transactions.</li>
                <li><strong>Firebase Messaging:</strong> Manage the delivery of notifications.</li>
            </ul>

            <h3>2.2. Internal Use</h3>
            <p>
                We only access aggregated and anonymous data to:
            </p>
            <ul>
                <li>Improve application functionality.</li>
                <li>Optimize user experience.</li>
                <li>Analyze overall performance.</li>
                <li>Make decisions regarding future updates.</li>
            </ul>

            <div className="separator"></div>

            <h2>3. Advertising</h2>
            <h3>3.1. Advertising Services</h3>
            <p>
                Advertising is managed exclusively by Google AdMob, which may:
            </p>
            <ul>
                <li>Use your device’s advertising identifier.</li>
                <li>Display interest-based ads.</li>
                <li>Collect usage data to optimize ads.</li>
            </ul>

            <h3>3.2. Advertising Consent</h3>
            <p>
                We use Google’s User Messaging Platform (UMP) to:
            </p>
            <ul>
                <li>Manage your consent for advertising.</li>
                <li>Handle privacy preferences.</li>
                <li>Comply with privacy regulations.</li>
            </ul>

            <div className="separator"></div>

            <h2>4. In-App Purchases</h2>
            <h3>4.1. Google Play Billing</h3>
            <p>
                All transactions are processed exclusively by Google Play Billing:
            </p>
            <ul>
                <li>The payment information is managed directly by Google.</li>
                <li>We do not have access to your financial data.</li>
                <li>Transactions follow Google Play's security standards.</li>
            </ul>

            <div className="separator"></div>

            <h2>5. Security</h2>
            <p>
                The security of data is guaranteed by:
            </p>
            <ul>
                <li>Google’s security protocols for its services.</li>
                <li>Secure connections with Firebase services.</li>
                <li>Google Play's security standards.</li>
            </ul>

            <div className="separator"></div>

            <h2>6. Your Rights and Options</h2>
            <p>
                You can:
            </p>
            <ul>
                <li>Manage your advertising preferences in your device settings.</li>
                <li>Control notification permissions.</li>
                <li>Manage your data through your Google account.</li>
                <li>Disable data collection for analytics when this option is available.</li>
            </ul>

            <div className="separator"></div>

            <h2>7. Changes to the Policy</h2>
            <p>
                Changes to this policy will be notified through:
            </p>
            <ul>
                <li>Application updates.</li>
                <li>In-app messages (managed by Firebase).</li>
                <li>The application’s user interface.</li>
            </ul>

            <div className="separator"></div>

            <h2>8. Contact</h2>
            <p>
                For any questions regarding this policy, you can:
            </p>
            <ul>
                <li>Use the contact section in the application.</li>
                <li>Contact us through Google Play Store.</li>
                <li>Use the available contact options in the app.</li>
            </ul>

            <div className="separator"></div>

            <h2>9. Consent</h2>
            <p>
                By using the application, you agree to:
            </p>
            <ul>
                <li>The use of Google services for analytics and advertising.</li>
                <li>The processing of purchases through Google Play.</li>
                <li>The management of notifications by Firebase (optional).</li>
            </ul>

            <p>Note: All personal information is handled exclusively by the aforementioned Google services, following their privacy and security standards. The "Himnario LLDM" application does not store or directly process any personally identifiable information.</p>
        </div>
    );
};

export default PoliticaDePrivacidad;
