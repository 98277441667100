import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import PoliticaDePrivacidadEs from './components/Himnario-PoliticaDePrivacidad-es';
import PoliticaDePrivacidadEn from './components/Himnario-PoliticaDePrivacidad-en';
import './App.css';

function App() {
    return (
        <Router>
            <div className='App'>
               
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div style={{ height: '100vh' }}>
                                <Parallax pages={2} style={{ top: '0', left: '0' }} className="animation">
                                    <ParallaxLayer offset={0} speed={0.3}>
                                        <div className="animation_layer parallax" id="background"></div>
                                    </ParallaxLayer>
                                    <ParallaxLayer offset={0} speed={0.15}>
                                        <div className="animation_layer parallax" id="logo"></div>
                                    </ParallaxLayer>
                                    <ParallaxLayer offset={0} speed={0.3}>
                                        <div className="animation_layer parallax bold-text" id="element1">
                                            Himnario app
                                        </div>
                                    </ParallaxLayer>
                                    <ParallaxLayer offset={0} speed={0.3}>
                                        <div className="animation_layer parallax bold-text" id="element2">
                                            S.C.A. Group System
                                        </div>
                                    </ParallaxLayer>
                                </Parallax>
                            </div>
                        }
                    />
                    <Route path="/himnariolldm/politica-de-privacidad-es" element={<PoliticaDePrivacidadEs />} />
                    <Route path="/himnariolldm/politica-de-privacidad-en" element={<PoliticaDePrivacidadEn />} />
                </Routes>
            </div>  
        </Router>
    );
}

export default App;
