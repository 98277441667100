import React from 'react';
import '../styles/PoliticaDePrivacidad.css'; // Asegúrate de que el archivo CSS está en la misma carpeta
import logo from '../images/himnario-logo.png'; // Cambia esto por la ruta correcta de tu logotipo

const PoliticaDePrivacidad = () => {
    return (
        <div className="politica-privacidad-container">
            {/* Logotipo en la parte superior */}
            <img src={logo} alt="Logotipo de Himnario LLDM" className="logo" />
            <ul>
                <h1>Política de Privacidad</h1>
            </ul>
            <div className="separator"></div>
            <ul>
                <p><strong>Última actualización:</strong> 18 de Octubre de 2024</p>
            </ul>

            <p>Agradecemos su interés y confianza en la aplicación "Himnario LLDM". La privacidad y seguridad de sus datos son de suma importancia para nosotros. Esta Política de Privacidad tiene como objetivo proporcionarle información clara sobre el manejo de datos cuando utiliza nuestra aplicación.</p>

            <div className="separator"></div>

            <h2>1. Recolección de Información</h2>
            <h3>1.1. Información Personal</h3>
            <p>
                La aplicación "Himnario LLDM" no recopila ni almacena directamente ninguna información personal identificable de los usuarios. Sin embargo, nuestra aplicación utiliza servicios de Google que pueden recopilar cierta información:
            </p>
            <ul>
                <li><strong>Google Play Billing:</strong> Maneja la información necesaria para procesar compras dentro de la aplicación</li>
                <li><strong>Firebase Cloud Messaging:</strong> Gestiona los tokens de dispositivo para notificaciones push</li>
                <li><strong>Google AdMob:</strong> Administra identificadores de publicidad y preferencias de anuncios</li>
                <li><strong>Firebase Analytics:</strong> Recopila datos anónimos de uso</li>
            </ul>
            <p>
                Toda la información personal es manejada directamente por estos servicios de Google bajo sus propias políticas de privacidad y estándares de seguridad.
            </p>

            <h3>1.2. Información de Uso</h3>
            <p>
                Los servicios de Google integrados en nuestra aplicación pueden recopilar:
            </p>
            <ul>
                <li>Estadísticas anónimas de uso de funciones</li>
                <li>Datos de rendimiento de la aplicación</li>
                <li>Patrones de navegación anónimos</li>
                <li>Información sobre errores</li>
                <li>Métricas de sesión</li>
            </ul>
            <p>
                Esta información es completamente anónima y es procesada por Firebase Analytics y Google Analytics.
            </p>

            <div className="separator"></div>

            <h2>2. Uso de la Información</h2>
            <h3>2.1. Uso por Servicios de Google</h3>
            <p>
                Los servicios de Google utilizan la información recopilada para:
            </p>
            <ul>
                <li><strong>Firebase:</strong> Proporcionar análisis anónimos de uso y comportamiento</li>
                <li><strong>AdMob:</strong> Gestionar la publicidad relevante</li>
                <li><strong>Play Billing:</strong> Procesar transacciones de compra</li>
                <li><strong>Firebase Messaging:</strong> Gestionar la entrega de notificaciones</li>
            </ul>

            <h3>2.2. Uso Interno</h3>
            <p>
                Nosotros únicamente accedemos a datos agregados y anónimos para:
            </p>
            <ul>
                <li>Mejorar la funcionalidad de la aplicación</li>
                <li>Optimizar la experiencia del usuario</li>
                <li>Analizar el rendimiento general</li>
                <li>Tomar decisiones sobre futuras actualizaciones</li>
            </ul>

            <div className="separator"></div>

            <h2>3. Publicidad</h2>
            <h3>3.1. Servicios de Publicidad</h3>
            <p>
                La publicidad es manejada exclusivamente por Google AdMob, que puede:
            </p>
            <ul>
                <li>Utilizar el identificador de publicidad de su dispositivo</li>
                <li>Mostrar anuncios basados en intereses</li>
                <li>Recopilar datos de uso para optimizar anuncios</li>
            </ul>

            <h3>3.2. Consentimiento de Publicidad</h3>
            <p>
                Utilizamos la User Messaging Platform (UMP) de Google para:
            </p>
            <ul>
                <li>Gestionar su consentimiento para la publicidad</li>
                <li>Manejar preferencias de privacidad</li>
                <li>Cumplir con regulaciones de privacidad</li>
            </ul>

            <div className="separator"></div>

            <h2>4. Compras In-App</h2>
            <h3>4.1. Google Play Billing</h3>
            <p>
                Todas las transacciones son procesadas exclusivamente por Google Play Billing:
            </p>
            <ul>
                <li>La información de pago es manejada directamente por Google</li>
                <li>No tenemos acceso a sus datos financieros</li>
                <li>Las transacciones siguen los estándares de seguridad de Google Play</li>
            </ul>

            <div className="separator"></div>

            <h2>5. Seguridad</h2>
            <p>
                La seguridad de los datos está garantizada por:
            </p>
            <ul>
                <li>Los protocolos de seguridad de Google para sus servicios</li>
                <li>Conexiones seguras con los servicios de Firebase</li>
                <li>Estándares de seguridad de Google Play</li>
            </ul>

            <div className="separator"></div>

            <h2>6. Sus Derechos y Opciones</h2>
            <p>
                Usted puede:
            </p>
            <ul>
                <li>Gestionar sus preferencias de publicidad en la configuración de su dispositivo</li>
                <li>Controlar los permisos de notificaciones</li>
                <li>Administrar sus datos a través de su cuenta de Google</li>
                <li>Desactivar la recopilación de datos de análisis cuando esta opción esté disponible</li>
            </ul>

            <div className="separator"></div>

            <h2>7. Cambios en la Política</h2>
            <p>
                Los cambios en esta política serán notificados mediante:
            </p>
            <ul>
                <li>Actualizaciones de la aplicación</li>
                <li>Mensajes in-app (gestionados por Firebase)</li>
                <li>La interfaz de usuario de la aplicación</li>
            </ul>

            <div className="separator"></div>

            <h2>8. Contacto</h2>
            <p>
                Para cualquier consulta sobre esta política, puede:
            </p>
            <ul>
                <li>Usar la sección de contacto en la aplicación</li>
                <li>Contactarnos a través de Google Play Store</li>
                <li>Utilizar las opciones de contacto disponibles en la app</li>
            </ul>

            <div className="separator"></div>

            <h2>9. Consentimiento</h2>
            <p>
                Al usar la aplicación, usted acepta:
            </p>
            <ul>
                <li>El uso de servicios de Google para análisis y publicidad</li>
                <li>El procesamiento de compras a través de Google Play</li>
                <li>La gestión de notificaciones por Firebase (opcional)</li>
            </ul>
            <p>
                Nota: Toda la información personal es manejada exclusivamente por los servicios de Google mencionados anteriormente, siguiendo sus estándares de privacidad y seguridad. La aplicación "Himnario LLDM" no almacena ni procesa directamente ninguna información personal identificable.
            </p>
        </div>
    );
};

export default PoliticaDePrivacidad;
